<template>
    <div>
        <particles :disableClick="true" />
        <van-tabs v-model="active_tab" scrollspy sticky>
            <van-tab title="个人信息">
                <van-cell-group style="background:#fff;width:calc(100% - 32px);margin:auto;border-radius:10px;overflow:hidden">
                    <template #title>
                        <van-icon name="manager-o" style="color:white;margin:4px;vertical-align:middle;font-size:16px" />
                        <strong style="color:white;margin-left:4px;vertical-align:middle;font-size:16px">个人信息</strong>
                    </template>
                    <van-field v-model="_name" name="姓名" label="姓名" placeholder="请输入您的姓名" />
                    <van-cell title="性别" title-class="van-field__label">
                        <van-radio-group v-model="_sex" direction="horizontal">
                            <van-radio name="1">男</van-radio>
                            <van-radio name="2">女</van-radio>
                        </van-radio-group>
                    </van-cell>
                    <van-cell title="血型" title-class="van-field__label">
                        <van-radio-group v-model="_blood_type" direction="horizontal">
                            <van-radio name="O">O</van-radio>
                            <van-radio name="A">A</van-radio>
                            <van-radio name="B">B</van-radio>
                            <van-radio name="AB">AB</van-radio>
                        </van-radio-group>
                    </van-cell>
                    <van-field readonly clickable label="出生日期" v-model="_birthday" placeholder="请选择您的出生日期" @click="show_birthday_picker = true" />
                    <van-popup v-model:show="show_birthday_picker" round position="bottom">
                        <van-datetime-picker type="date" @cancel="show_birthday_picker = false" @confirm="confirmBirthdayPicker" :min-Date="birthday_range[0]" :max-Date="birthday_range[1]" />
                    </van-popup>
                    <van-cell title="婚史" title-class="van-field__label">
                        <van-radio-group v-model="_married" direction="horizontal">
                            <van-radio name="0">未婚</van-radio>
                            <van-radio name="1">已婚</van-radio>
                        </van-radio-group>
                    </van-cell>
                    <van-field readonly clickable label="民族" v-model="_nation" placeholder="请选择您的民族" @click="show_nation_picker = true" />
                    <van-popup v-model:show="show_nation_picker" round position="bottom">
                        <van-picker :columns="nation_list" @cancel="show_nation_picker = false" @confirm="confirmNationPicker" />
                    </van-popup>
                    <van-field readonly clickable label="身高/体重" v-model="height_weight_text" placeholder="请选择您的身高与体重" @click="show_height_weight_picker = true" />
                    <van-popup v-model:show="show_height_weight_picker" round position="bottom">
                        <van-picker
                            :columns="[
                                { values: height_list, defaultIndex: 60 },
                                { values: weight_list, defaultIndex: 30 },
                            ]"
                            title="身高  /  体重"
                            @cancel="show_height_weight_picker = false"
                            @confirm="confirmHeightWeightPicker"
                        />
                    </van-popup>
                    <van-field readonly clickable label="血压" v-model="blood_pressure_text" placeholder="请选择您的血压值" @click="show_blood_pressure_picker = true" />
                    <van-popup v-model:show="show_blood_pressure_picker" round position="bottom">
                        <van-picker
                            :columns="[
                                { values: diastolic_list, defaultIndex: 55 },
                                { values: systolic_list, defaultIndex: 85 },
                            ]"
                            title="舒张压  /  收缩压"
                            @cancel="show_blood_pressure_picker = false"
                            @confirm="confirmBloodPressurePicker"
                        />
                    </van-popup>
                </van-cell-group>
            </van-tab>
            <van-tab title="既往病史">
                <van-cell-group style="background:#fff;width:calc(100% - 32px);margin:auto;border-radius:10px;overflow:hidden">
                    <template #title>
                        <van-icon name="manager-o" style="color:white;margin:4px;vertical-align:middle;font-size:16px" />
                        <strong style="color:white;margin-left:4px;vertical-align:middle;font-size:16px">既往病史</strong>
                        <div><span style="font-size:12px;color:#ccc;line-height:12px">可多选，若没有请选择“无”</span></div>
                    </template>
                    <van-checkbox-group v-model="_history_disease">
                        <van-cell v-for="(item, index) in history_disease_list" clickable :key="item" :title="item" @click="selectHistoryDisease(index)">
                            <template #right-icon>
                                <van-checkbox :shape="index == 0 ? 'round' : 'square'" :name="item" :ref="(el) => (history_disease_checkbox_ref_list[index] = el)" @click.stop="selectHistoryDisease(index)" />
                            </template>
                        </van-cell>
                    </van-checkbox-group>
                </van-cell-group>
            </van-tab>
            <van-tab title="家族病史">
                <van-cell-group style="background:#fff;width:calc(100% - 32px);margin:auto;border-radius:10px;overflow:hidden">
                    <template #title>
                        <van-icon name="manager-o" style="color:white;margin:4px;vertical-align:middle;font-size:16px" />
                        <strong style="color:white;margin-left:4px;vertical-align:middle;font-size:16px">家族病史</strong>
                        <div><span style="font-size:12px;color:#ccc;line-height:12px">可多选，若没有请选择“无”</span></div>
                    </template>
                    <van-checkbox-group v-model="_family_disease">
                        <van-cell v-for="(item, index) in family_disease_list" clickable :key="item" :title="item" @click="selectFamilyDisease(index)">
                            <template #right-icon>
                                <van-checkbox :shape="index == 0 ? 'round' : 'square'" :name="item" :ref="(el) => (family_disease_checkbox_ref_list[index] = el)" @click.stop="selectFamilyDisease(index)" />
                            </template>
                        </van-cell>
                    </van-checkbox-group>
                </van-cell-group>
            </van-tab>
            <van-tab title="长期服药">
                <van-cell-group style="background:#fff;width:calc(100% - 32px);margin:auto;border-radius:10px;overflow:hidden">
                    <template #title>
                        <van-icon name="manager-o" style="color:white;margin:4px;vertical-align:middle;font-size:16px" />
                        <strong style="color:white;margin-left:4px;vertical-align:middle;font-size:16px">长期服药</strong>
                        <div><span style="font-size:12px;color:#ccc;line-height:12px">指：连续服用6个月以上，平均每日服用一次以上</span></div>
                    </template>
                    <van-checkbox-group v-model="_long_time_drug">
                        <van-cell v-for="(item, index) in long_time_drug_list" clickable :key="item" :title="item" @click="selectLongTimeDrug(index)">
                            <template #right-icon>
                                <van-checkbox :shape="index == 0 ? 'round' : 'square'" :name="item" :ref="(el) => (long_time_drug_checkbox_ref_list[index] = el)" @click.stop="selectLongTimeDrug(index)" />
                            </template>
                        </van-cell>
                    </van-checkbox-group>
                </van-cell-group>
            </van-tab>
            <van-tab title="饮食偏好">
                <van-cell-group style="background:#fff;width:calc(100% - 32px);margin:auto;border-radius:10px;overflow:hidden" v-for="(item1, index1) in eat_prefer_list" :key="item1.question">
                    <template #title>
                        <div v-if="index1 == 0">
                            <van-icon name="manager-o" style="color:white;margin:4px;vertical-align:middle;font-size:16px" />
                            <strong style="color:white;margin-left:4px;vertical-align:middle;font-size:16px">饮食偏好</strong>
                            <div>
                                <span style="font-size:12px;color:#ccc;line-height:12px">{{ item1.question }}</span>
                            </div>
                        </div>
                        <div v-else>
                            <div style="margin-top:-8px">
                                <span style="font-size:12px;color:#ccc;line-height:12px">{{ item1.question }}</span>
                            </div>
                        </div>
                    </template>
                    <van-checkbox-group v-model="_eat_prefer_list[index1]" v-if="item1.type == 'multiple'">
                        <van-cell v-for="(item2, index2) in item1.options" clickable :key="item2" :title="item2" @click="selectEatPrefer(index1, index2)">
                            <template #right-icon>
                                <van-checkbox shape="square" :name="item2" :ref="(el) => (eat_prefer_checkbox_ref_list[index1][index2] = el)" @click.stop="selectEatPrefer(index1, index2)" />
                            </template>
                        </van-cell>
                    </van-checkbox-group>
                    <van-radio-group v-model="_eat_prefer_list[index1][0]" v-else-if="item1.type == 'single'">
                        <van-cell v-for="item2 in item1.options" clickable :key="item2" :title="item2" @click="_eat_prefer_list[index1] = [item2]">
                            <template #right-icon>
                                <van-radio :name="item2" @click.stop="_eat_prefer_list[index1] = [item2]" />
                            </template>
                        </van-cell>
                    </van-radio-group>
                </van-cell-group>
            </van-tab>
            <van-tab title="烟酒情况">
                <van-cell-group style="background:#fff;width:calc(100% - 32px);margin:auto;border-radius:10px;overflow:hidden">
                    <template #title>
                        <van-icon name="manager-o" style="color:white;margin:4px;vertical-align:middle;font-size:16px" />
                        <strong style="color:white;margin-left:4px;vertical-align:middle;font-size:16px">烟酒情况</strong>
                    </template>
                    <div v-for="(item, index) in smoke_list" :key="item.question">
                        <div v-if="index == 0 || (_smoke_list[0] && _smoke_list[0] != smoke_list[0].options[0])">
                            <van-field readonly clickable :label="item.question" v-model="_smoke_list[index]" placeholder="请点击进行选择" @click="show_smoke_picker_list[index] = true" />
                            <van-popup v-model:show="show_smoke_picker_list[index]" round position="bottom">
                                <van-picker :columns="smoke_list[index].options" :title="smoke_list[index].question" @cancel="show_smoke_picker_list[index] = false" @confirm="smoke_picker_confirm_list[index]" />
                            </van-popup>
                        </div>
                    </div>
                    <div v-for="(item, index) in drink_list" :key="item.question">
                        <div v-if="index == 0 || (_drink_list[0] && _drink_list[0] != drink_list[0].options[0])">
                            <van-field readonly clickable :label="item.question" v-model="_drink_list[index]" placeholder="请点击进行选择" @click="show_drink_picker_list[index] = true" />
                            <van-popup v-model:show="show_drink_picker_list[index]" round position="bottom">
                                <van-picker :columns="drink_list[index].options" :title="drink_list[index].question" @cancel="show_drink_picker_list[index] = false" @confirm="drink_picker_confirm_list[index]" />
                            </van-popup>
                        </div>
                    </div>
                </van-cell-group>
            </van-tab>
            <van-tab title="生活指标">
                <!-- 运动 exercise -->
                <van-cell-group style="background:#fff;width:calc(100% - 32px);margin:auto;border-radius:10px;overflow:hidden">
                    <template #title>
                        <van-icon name="manager-o" style="color:white;margin:4px;vertical-align:middle;font-size:16px" />
                        <strong style="color:white;margin-left:4px;vertical-align:middle;font-size:16px">生活指标</strong>
                        <div><span style="font-size:12px;color:#ccc;line-height:12px">运动情况</span></div>
                    </template>
                    <div v-for="(item, index) in exercise_list" :key="item.question">
                        <div v-if="index == 0 || (_exercise_list[0] && _exercise_list[0] != exercise_list[0].options[0])">
                            <van-field readonly clickable :label="item.question" v-model="_exercise_list[index]" placeholder="请点击进行选择" @click="show_exercise_picker_list[index] = true" />
                            <van-popup v-model:show="show_exercise_picker_list[index]" round position="bottom">
                                <van-picker :columns="exercise_list[index].options" :title="exercise_list[index].question" @cancel="show_exercise_picker_list[index] = false" @confirm="exercise_picker_confirm_list[index]" />
                            </van-popup>
                        </div>
                    </div>
                </van-cell-group>
                <!-- 工作 work -->
                <van-cell-group style="background:#fff;width:calc(100% - 32px);margin:auto;border-radius:10px;overflow:hidden" v-for="(item1, index1) in work_list" :key="item1.question">
                    <template #title>
                        <div style="margin-top:-8px">
                            <span style="font-size:12px;color:#ccc;line-height:12px">{{ item1.question }}</span>
                        </div>
                    </template>
                    <van-checkbox-group v-model="_work_list[index1]" v-if="item1.type == 'multiple'">
                        <van-cell v-for="(item2, index2) in item1.options" clickable :key="item2" :title="item2" @click="selectWork(index1, index2)">
                            <template #right-icon>
                                <van-checkbox shape="square" :name="item2" :ref="(el) => (work_checkbox_ref_list[index1][index2] = el)" @click.stop="selectWork(index1, index2)" />
                            </template>
                        </van-cell>
                    </van-checkbox-group>
                    <van-radio-group v-model="_work_list[index1][0]" v-else-if="item1.type == 'single'">
                        <van-cell v-for="item2 in item1.options" clickable :key="item2" :title="item2" @click="_work_list[index1] = [item2]">
                            <template #right-icon>
                                <van-radio :name="item2" @click.stop="_work_list[index1] = [item2]" />
                            </template>
                        </van-cell>
                    </van-radio-group>
                </van-cell-group>
                <!-- 情绪 emotion -->
                <van-cell-group style="background:#fff;width:calc(100% - 32px);margin:auto;border-radius:10px;overflow:hidden" v-for="(item1, index1) in emotion_list" :key="item1.question">
                    <template #title>
                        <div style="margin-top:-8px">
                            <span style="font-size:12px;color:#ccc;line-height:12px">{{ item1.question }}</span>
                        </div>
                    </template>
                    <van-checkbox-group v-model="_emotion_list[index1]" v-if="item1.type == 'multiple'">
                        <van-cell v-for="(item2, index2) in item1.options" clickable :key="item2" :title="item2" @click="selectEmotion(index1, index2)">
                            <template #right-icon>
                                <van-checkbox shape="square" :name="item2" :ref="(el) => (emotion_checkbox_ref_list[index1][index2] = el)" @click.stop="selectEmotion(index1, index2)" />
                            </template>
                        </van-cell>
                    </van-checkbox-group>
                    <van-radio-group v-model="_emotion_list[index1][0]" v-else-if="item1.type == 'single'">
                        <van-cell v-for="item2 in item1.options" clickable :key="item2" :title="item2" @click="_emotion_list[index1] = [item2]">
                            <template #right-icon>
                                <van-radio :name="item2" @click.stop="_emotion_list[index1] = [item2]" />
                            </template>
                        </van-cell>
                    </van-radio-group>
                </van-cell-group>
            </van-tab>
        </van-tabs>
        <van-button class="submit_button" type="primary" @click="submit" :loading="button_loading">{{ continue_test ? '提交信息，进入下一步' : '保存' }}</van-button>
    </div>
</template>

<script>
import particles from '../components/particles.vue';
import moment from 'moment';
import { Dialog, Toast } from 'vant';
export default {
    components: {
        particles,
    },
    data() {
        // 用于生成一个选项列表，为picker提供可选项
        let f = (min, max, unit = '') => {
            let t = [];
            for (let i = min; i <= max; i++) {
                t.push('' + i + unit);
            }
            return t;
        };
        return {
            // 是继续第一步测试，还是保存后返回
            continue_test: !!this.$route.query['ct'],
            // 激活的tab名称
            active_tab: '个人信息',
            _name: '',
            _sex: '',
            _birthday: '',
            // 是否显示生日选择框
            show_birthday_picker: false,
            // 生日可选范围
            birthday_range: [new moment().subtract(100, 'y').toDate(), new Date()],
            _nation: '汉族',
            // 是否显示民族选择框
            show_nation_picker: false,
            nation_list: ['汉族', '蒙古族', '回族', '苗族', '傣族', '傈僳族', '藏族', '壮族', '朝鲜族', '高山族', '纳西族', '布朗族', '阿昌族', '怒族', '鄂温克族', '鄂伦春族', '赫哲族', '门巴族', '白族', '保安族', '布依族', '达斡尔族', '德昂族', '东乡族', '侗族', '独龙族', '俄罗斯族 ', '哈尼族', '哈萨克族', '基诺族', '京族', '景颇族', '柯尔克孜族', '拉祜族', '黎族', '珞巴族', '满族', '毛南族', '仫佬族', '普米族', '羌族', '撒拉族', '畲族', '水族', '塔吉克族', '塔塔尔族', '土家族', '仡佬族', '土族', '佤族', '维吾尔族', '乌孜别克族', '锡伯族', '瑶族', '裕固族', '彝族'],
            _blood_type: null,
            _married: '',
            // 显示在页面上的身高与体重的文本
            height_weight_text: '',
            _height: '',
            _weight: '',
            // 身高可选值
            height_list: f(110, 220, '厘米'),
            // 体重可选值
            weight_list: f(30, 120, '千克'),
            // 是否显示身高体重选择框
            show_height_weight_picker: false,
            // 显示在页面上的血压文本：xx / xx mmHg
            blood_pressure_text: '',
            _systolic: null,
            _diastolic: null,
            // 收缩压可选值
            systolic_list: f(40, 200, 'mmHg'),
            // 舒张压可选值
            diastolic_list: f(20, 150, 'mmHg'),
            // 是否显示血压选择框
            show_blood_pressure_picker: false,
            // 用于选择的既往病史的病的列表
            history_disease_list: ['无'],
            _history_disease: ['无'],
            // 存放既往病史控件元素数据的列表，用于判断无选项及相关业务逻辑
            history_disease_checkbox_ref_list: [],
            // 用于选择的家族病史的病的列表
            family_disease_list: ['无'],
            _family_disease: ['无'],
            // 存放家族病史控件元素数据的列表，用于判断无选项及相关业务逻辑
            family_disease_checkbox_ref_list: [],
            // 用于选择的长期服药的药的列表
            long_time_drug_list: ['无'],
            _long_time_drug: ['无'],
            // 存放长期服药控件元素数据的列表，用于判断无选项及相关业务逻辑
            long_time_drug_checkbox_ref_list: [],
            // 饮食偏好下的题目组
            eat_prefer_list: [],
            _eat_prefer_list: [],
            // 存放饮食偏好控件元素数据的二维列表，用于判断无选项及相关业务逻辑
            eat_prefer_checkbox_ref_list: [],
            // 吸烟相关的题目组（多道题，后续题目是否显示由第一道控制）
            smoke_list: [],
            show_smoke_picker_list: [],
            smoke_picker_confirm_list: [],
            _smoke_list: [],
            // 饮酒相关的题目组（多道题，后续题目是否显示由第一道控制）
            drink_list: [],
            show_drink_picker_list: [],
            drink_picker_confirm_list: [],
            _drink_list: [],
            // 运动相关的题目组（多道题，后续题目是否显示由第一道控制）
            exercise_list: [],
            show_exercise_picker_list: [],
            exercise_picker_confirm_list: [],
            _exercise_list: [],
            // 工作类题目组
            work_list: [],
            _work_list: [],
            // 存放工作类题目控件元素数据的二维列表，用于判断无选项及相关业务逻辑
            work_checkbox_ref_list: [],
            // 情绪类题目组
            emotion_list: [],
            _emotion_list: [],
            // 存放情绪类题目控件元素数据的二维列表，用于判断无选项及相关业务逻辑
            emotion_checkbox_ref_list: [],
            // 底部按钮加载状态
            button_loading: false,
        };
    },
    methods: {
        confirmNationPicker(value) {
            this._nation = value;
            this.show_nation_picker = false;
        },
        confirmBirthdayPicker(value) {
            this._birthday = new moment(value).format('YYYY-MM-DD');
            this.show_birthday_picker = false;
        },
        confirmHeightWeightPicker(value) {
            this._height = value[0].slice(0, -2);
            this._weight = value[1].slice(0, -2);
            this.height_weight_text = `${this._height}cm / ${this._weight}kg`;
            this.show_height_weight_picker = false;
        },
        confirmBloodPressurePicker(value) {
            this._diastolic = value[0].slice(0, -4);
            this._systolic = value[1].slice(0, -4);
            this.blood_pressure_text = `${this._diastolic} / ${this._systolic} mmHg`;
            this.show_blood_pressure_picker = false;
        },
        selectHistoryDisease(index) {
            if (index == 0) {
                this._history_disease = ['无'];
            } else {
                this.history_disease_checkbox_ref_list[index].toggle();
                if (this._history_disease[0] == '无') this._history_disease.splice(0, 1);
            }
        },
        selectFamilyDisease(index) {
            if (index == 0) {
                this._family_disease = ['无'];
            } else {
                this.family_disease_checkbox_ref_list[index].toggle();
                if (this._family_disease[0] == '无') this._family_disease.splice(0, 1);
            }
        },
        selectLongTimeDrug(index) {
            if (index == 0) {
                this._long_time_drug = ['无'];
            } else {
                this.long_time_drug_checkbox_ref_list[index].toggle();
                if (this._long_time_drug[0] == '无') this._long_time_drug.splice(0, 1);
            }
        },
        selectEatPrefer(index1, index2) {
            this.eat_prefer_checkbox_ref_list[index1][index2].toggle();
        },
        confirmSmokePicker(index, value) {
            this._smoke_list[index] = value;
            this.show_smoke_picker_list[index] = false;
            if (index == 0 && this.smoke_list[index].options.indexOf(value) == 0) {
                for (let i in this.smoke_list) {
                    this._smoke_list[i] = this.smoke_list[i].options[0];
                }
            }
        },
        confirmDrinkPicker(index, value) {
            this._drink_list[index] = value;
            this.show_drink_picker_list[index] = false;
            if (index == 0 && this.drink_list[index].options.indexOf(value) == 0) {
                for (let i in this.drink_list) {
                    this._drink_list[i] = this.drink_list[i].options[0];
                }
            }
        },
        confirmExercisePicker(index, value) {
            this._exercise_list[index] = value;
            this.show_exercise_picker_list[index] = false;
            if (index == 0 && this.exercise_list[index].options.indexOf(value) == 0) {
                for (let i in this.exercise_list) {
                    this._exercise_list[i] = this.exercise_list[i].options[0];
                }
            }
        },
        selectWork(index1, index2) {
            this.work_checkbox_ref_list[index1][index2].toggle();
        },
        selectEmotion(index1, index2) {
            this.emotion_checkbox_ref_list[index1][index2].toggle();
        },
        submit() {
            let base_info = {
                name: this._name,
                sex: this._sex,
                blood_type: this._blood_type,
                birthday: this._birthday,
                married: this._married,
                nation: this._nation,
                height: this._height,
                weight: this._weight,
                systolic: this._systolic,
                diastolic: this._diastolic,
                history_disease: this._history_disease,
                family_disease: this._family_disease,
                long_time_drug: this._long_time_drug,
            };
            let flag = false;
            if (!flag && base_info.name.length < 1) flag = '提示,请输入您的姓名';
            if (!flag && base_info.name.length > 15) flag = '提示,姓名最多可输入15个字符';
            if (!flag && base_info.sex == '') flag = '提示,请选择您的性别';
            if (!flag && base_info.birthday == '') flag = '提示,请选择您的生日';
            if (!flag && base_info.married == '') flag = '提示,请选择您的婚姻状况';
            if (!flag && base_info.nation == '') flag = '提示,请选择您的民族';
            if (!flag && (base_info.height == '' || base_info.weight == '')) flag = '提示,请选择您的身高与体重';
            let answer_list = [];
            let temp_question_list = [].concat(this.smoke_list, this.drink_list, this.exercise_list);
            let temp_answer_list = [].concat(this._smoke_list, this._drink_list, this._exercise_list);
            for (let i in temp_question_list) {
                if (!flag && temp_answer_list[i] == '') {
                    flag = `问卷尚未填写完成,未填写的题目：${temp_question_list[i].question}`;
                }
                answer_list.push({
                    id: temp_question_list[i].id,
                    answers: [temp_answer_list[i]],
                });
            }
            temp_question_list = [].concat(this.eat_prefer_list, this.work_list, this.emotion_list);
            temp_answer_list = [].concat(this._eat_prefer_list, this._work_list, this._emotion_list);
            for (let i in temp_question_list) {
                if (!flag && temp_question_list[i].type == 'single' && temp_answer_list[i].length == 0) {
                    flag = `问卷尚未填写完成,未填写的题目：${temp_question_list[i].question}`;
                }
                answer_list.push({
                    id: temp_question_list[i].id,
                    answers: temp_answer_list[i],
                });
            }
            if (flag) {
                Dialog.alert({ title: flag.split(',')[0], message: flag.split(',')[1] });
                return;
            }
            if (base_info.history_disease.length == 1 && base_info.history_disease[0] == '无') base_info.history_disease = [];
            if (base_info.family_disease.length == 1 && base_info.family_disease[0] == '无') base_info.family_disease = [];
            if (base_info.long_time_drug.length == 1 && base_info.long_time_drug[0] == '无') base_info.long_time_drug = [];
            this.button_loading = true;
            Toast.loading({ message: '正在保存...', duration: 0 });
            this.$api('saveBaseInfo', { base_info, answer_list })
                .then((res) => {
                    this.button_loading = false;
                    Toast.success({ message: '保存完成！' });
                    setTimeout(() => {
                        if (this.continue_test) {
                            this.$router.push('/step1');
                        } else {
                            this.$router.push('/report');
                        }
                    }, 500);
                })
                .catch((res) => {
                    this.button_loading = false;
                    Toast.success({ message: '保存失败！' });
                });
            // this.$router.push('/step1');
            // this.$api('saveBaseInfo', info).then((res) => {
            //     if (this.continue_test) {
            //         this.$router.push('/step1');
            //     } else {
            //         this.$router.push('/report');
            //     }
            // });
        },
    },
    beforeMount() {
        this.$api('getBaseIssue').then((res) => {
            this.history_disease_list = ['无'].concat(res.history_disease_list);
            this.family_disease_list = ['无'].concat(res.family_disease_list);
            this.long_time_drug_list = ['无'].concat(res.long_time_drug_list);
            res.question_list.forEach((item) => {
                let group = item.group;
                switch (group) {
                    case 'eat_prefer':
                    case 'work':
                    case 'emotion':
                        this[`${group}_list`].push(item);
                        this[`_${group}_list`].push([]);
                        this[`${group}_checkbox_ref_list`].push([]);
                        break;
                    case 'smoke':
                    case 'drink':
                    case 'exercise':
                        this[`${group}_list`].push(item);
                        this[`show_${group}_picker_list`].push(false);
                        this[`_${group}_list`].push('');
                        break;
                }
            });
            ['Smoke', 'Drink', 'Exercise'].forEach((group) => {
                for (let i = 0; i < this[`${group.toLowerCase()}_list`].length; i++) {
                    this[`${group.toLowerCase()}_picker_confirm_list`].push((value) => {
                        this[`confirm${group}Picker`](i, value);
                    });
                }
            });
            // this.eat_prefer_list = res.eat_prefer;
            // for (let i = 0; i < res.eat_prefer.length; i++) {
            //     this._eat_prefer_list.push([]);
            //     this.eat_prefer_checkbox_ref_list.push([]);
            // }
            // this.smoke_list = res.smoke;
            // for (let i = 0; i < res.smoke.length; i++) {
            //     this.show_smoke_picker_list.push(false);
            //     this._smoke_list.push('');
            //     this.smoke_picker_confirm_list.push((value) => {
            //         this.confirmSmokePicker(i, value);
            //     });
            // }
            // this.drink_list = res.drink;
            // for (let i = 0; i < res.drink.length; i++) {
            //     this.show_drink_picker_list.push(false);
            //     this._drink_list.push('');
            //     this.drink_picker_confirm_list.push((value) => {
            //         this.confirmDrinkPicker(i, value);
            //     });
            // }
        });
    },
};
</script>

<style scoped>
.submit_button {
    border-radius: 999px;
    width: calc(100% - 32px);
    margin: 20px 16px 40px;
}
</style>
